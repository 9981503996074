.fUpload_formControl {
  width: '100%';
  max-width: '100%';
}

.fUpload_container {
  /* transform: translateY(-100%); */
}

.fUpload_container p {
  color: red;
  text-align: center;
}

.fUpload_drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 100px;
  /* border: 1px dashed #5f5f5f; */
}

.fUpload_upload-icon {
  width: 50px;
  height: 50px;
  background: url(../../../assets/images/icons/upload.png) no-repeat center center;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.fUpload_drop-message {
  text-align: center;
  color: #5f5f5f;
  font-family: Arial;
  font-size: 20px;
}

.fUpload_file-display-container {
  width: 100%;
}

.fUpload_file-status-bar {
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 50px;
  height: 50px;
}

.fUpload_file-status-bar > div {
  overflow: hidden;
}

.fUpload_file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.fUpload_file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 70px;
  color: #5f5f5f;
}

.fUpload_file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}

.fUpload_file-error-message {
  color: red;
}

.fUpload_file-type-logo {
  width: 50px;
  height: 50px;
  background: url(../../../assets/images/icons/file.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
}

.fUpload_file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.fUpload_file-remove {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  /* color: red; */
  margin-right: -10px;
}

.fUpload_modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.fUpload_modal .fUpload_overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.fUpload_modal .fUpload_modal-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.fUpload_close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
  z-index: 2;
}

.fUpload_file-upload-btn {
  color: white;
  text-transform: uppercase;
  outline: none;
  background-color: #5f5f5f;
  font-weight: bold;
  padding: 8px 15px;
  margin-bottom: 5px;
}

.fUpload_file-input {
  display: none;
}

.fUpload_upload-modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.fUpload_upload-modal .fUpload_overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.fUpload_progress-container {
  background: white;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;
  gap: 12px;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  z-index: 1;
}

.fUpload_progress-container span {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-size: 20px;
}

.fUpload_progress-bar {
  position: absolute;
  background-color: #5f5f5f;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.fUpload_error {
  color: red;
}
