@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;700&family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  /* max-height: 100%; */
  min-height: 100%;
  font-family: 'Domine', serif;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.ck-rounded-corners .ck.ck-balloon-panel,
.ck.ck-balloon-panel.ck-rounded-corners {
  z-index: 10055 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiTableRow-head th {
  background-color: #ffffff;
}

/* tr:nth-child(odd) {
  background: #f1f1f1;
} */

.scroll-to-top {
  position:fixed;
  bottom: 12px;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #8B0000;
  color: #FFF;
}
.scroll-to-top p {
  margin-top: 10px;
  animation: scrollTop 0.5s alternate ease infinite;
}

@keyframes scrollTop {
  from {
    transform: translateY(2px);
  } to {
    transform: translateY(-1px);
  }
}

.ck-editor__editable_inline {
  min-height: 200px !important;
  /* height: calc(100vh - 45rem); */
}