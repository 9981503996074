/* .Telephone {
  color: #b3e5fc;
  font-weight: 600;
  background-color: #b3e5fc;
}
.Video {
  background-color: #dcedc8;
}

.Onsite {
  background-color: #ffe0b2;
}

.Translation {
  background-color: #fff9c4;
}

.Transcription_Audio {
  background-color: #ffcdd2;
}

.Transcription_Video {
  background-color: #e1bee7;
}

.Quotation {
  background-color: #a5d6a7;
}

.Booking {
  background-color: #b39ddb;
}

.Cancel {
  background-color: #80deea;
} */

.Telephone {
  background-color: #bbdefb;
}
.Video {
  background-color: #dcedc8;
}

.Onsite {
  background-color: #ffe0b2;
}

.Translation {
  background-color: #fff9c4;
}

.Transcription_Audio {
  background-color: #ffccbc;
}

.Transcription_Video {
  background-color: #d1c4e9;
}

/* Services */
.Booking {
  background-color: #e1bee7;
}

.Quotation {
  background-color: #c5cae9;
}

.Confirmed {
  background-color: #ffccff8c; /*b3e5fc*/
}

.Timesheet,
.Submitted {
  background-color: #b2ebf2;
}

.Approved {
  background-color: #b2dfdb;
}

.Paid {
  background-color: #c8e6c9;
}

.Cancel {
  background-color: #ffcdd2;
}

.Quote {
  background-color: #ffe0b2;
}

.Quote-Supplied {
  background-color: #f5f5f5;
}
